import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { notification } from 'antd'

const firebaseConfig = {
  apiKey: "AIzaSyAdSDP8TkQgDfG-hllZo2zlyj5lwHAXN_g",
  authDomain: "lochimarket-5bf80.firebaseapp.com",
  projectId: "lochimarket-5bf80",
  storageBucket: "lochimarket-5bf80.appspot.com",
  messagingSenderId: "235389567834",
  appId: "1:235389567834:web:ed9a241fb6ae2fecd4a963"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenData = () => {
  return getToken(messaging, { vapidKey: 'BKD1cNFIvRnfmXyzI7yqr9HF52ihLiW1yyd0qcEcojaJQuHDimrW4uY_hKTHipmlaD8XcHPNoXrO9flFzwQ6aOQ' }).then((currentToken) => {
    if (currentToken) {
      console.log('@@@ Firebase Registration TOKEN =====', currentToken);
      localStorage.setItem('fcmToken', currentToken.toString())
    } else {
      console.log('@@@ Firebase No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('@@@ Firebase Error: An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
  });

onMessage(messaging, (payload) => {
  console.log('@@@ Notification Payload =======', payload);
  notification.open({
    message: payload.notification.title,
    description:
      payload.notification.body,
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
  const event = new CustomEvent('notificationArrivedEvent', {
    detail: "Count updated",
  });
  document.dispatchEvent(event);
});