// App.js - WEB
import React, { Component } from 'react';
import WebRoutes from './WebRoutes';
import HomeScreen from '../../components/src/HomeScreen';
import './App.css';
import 'antd/dist/antd.css';
import { getTokenData } from './firebaseInit.js';

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}

class App extends Component {
  constructor(props) {
    super();
  }


  async componentDidMount() {
    Notification.requestPermission().then((permission) => {
      console.log('@@@ Notification Permission ======', permission);
      getTokenData();
    });
    if (window.location.pathname === '/landingpage') {
      this.setState({ runTour: true });
    }
  }

  render() {

    return (
      <>
        <WebRoutes {...this.props} />    
      </>
    );
  }
}

export default App;