import React, { PureComponent } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

export type Props = RouteProps & {
  history: any;
  isAlowedBeforeLogin?: boolean;
};

interface S {
  loading: any;
}


const routes = [
 
  {
    route: "/homepage",
    allowedRole: "Vendor"
  },
  {
    route: "/landingpage",
    allowedRole: "Shopper"
  },
  {
    route: "/new-product",
    allowedRole: "Vendor"
  },
  {
    route: "/sell",
    allowedRole: "Both"
  },
  {
    route: "/contactus",
    allowedRole: "Both"
  },
  {
    route: "/settings",
    allowedRole: "Both"
  },
  {
    route: "/editUserProfile",
    allowedRole: "Shopper"
  },
  {
    route: "/userProfile",
    allowedRole: "Shopper"
  },
  {
    route: "/chat",
    allowedRole: "Both"
  },
  {
    route: "/selectedProduct",
    allowedRole: "Shopper"
  },
  {
    route: "/buyNow",
    allowedRole: "Shopper"
  },
  {
    route: "/paymentMethod",
    allowedRole: "Shopper"
  },
  {
    route: "/paymentMethod",
    allowedRole: "Shopper"
  },
  {
    route: "/purchaseSummary",
    allowedRole: "Shopper"
  },
  {
    route: "/shopper_profile",
    allowedRole: "Vendor"
  },
  {
    route: "/shopper_profile/:accountId",
    allowedRole: "Vendor"
  },
  {
    route: "/manage_order",
    allowedRole: "Vendor"
  },
  {
    route: "/orderHistory",
    allowedRole: "Shopper"
  },
  {
    route: "/orderTrack",
    allowedRole: "Shopper"
  },
  {
    route: "/shopperNotification",
    allowedRole: "Shopper"
  },
  {
    route: "/vendorprofile",
    allowedRole: "Vendor"
  },
  {
    route: "/new-product",
    allowedRole: "Vendor"
  },
  {
    route: "/editproductinfo",
    allowedRole: "Vendor"
  },
  {
    route: "/order-detail/:orderId",
    allowedRole: "Vendor"
  },
  {
    route: "/orderCancel",
    allowedRole: "Shopper"
  },
  {
    route: "/productVenderProfile",
    allowedRole: "Shopper"
  },
  {
    route: "/shoppingcartpage",
    allowedRole: "Shopper"
  },
  {
    route: "/shoppingCartPackages",
    allowedRole: "Shopper"
  },

  {
    route: "/shoppingpayment",
    allowedRole: "Shopper"
  },
  {
    route: "/cartpurchaseSummary",
    allowedRole: "Shopper"
  },
  {
    route: "/favourites",
    allowedRole: "Shopper"
  },
  {
    route: "/notifications",
    allowedRole: "Both"
  }


]

export default class PrivateRoute extends PureComponent<
  Props,
  S
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  isAllowedVar: any;
  isLoggedInUserVar: any;

  componentDidMount = () => {
     this.isLoggedInUserVar = localStorage.getItem('token');
       if (this.isLoggedInUserVar) {
      this.setState({loading: false });
      this.isLoggedInUserVar = true
    } else {
      this.setState({ ...this.state, loading: false });
    }

  };

  handlePermissions = () => {
    this.isLoggedInUserVar = localStorage.getItem('token');
    if (this.isLoggedInUserVar) {
   this.setState({loading: false });
   this.isLoggedInUserVar = true
 } else {
   this.setState({ ...this.state, loading: false });
   this.isLoggedInUserVar = false
 }
    let UserDetails: any = localStorage.getItem('UserDetails');
    let currentRoute = window.location.pathname
    let userRole
    if (UserDetails) {
      UserDetails = JSON.parse(UserDetails);
      userRole = UserDetails?.role
    }

    let variableRoute = currentRoute.split("/")[1];
    if(variableRoute && variableRoute === "order-detail") {
      currentRoute = "/order-detail/:orderId";
    } else if(variableRoute && variableRoute === "shopper_profile") {
      currentRoute = "/shopper_profile/:accountId";
    }

   let currentRouteObj = routes.find((e: any) => e.route === currentRoute)
    console.log(currentRouteObj, userRole)
    if (currentRouteObj && (currentRouteObj.allowedRole === userRole || userRole === "Both" || currentRouteObj.allowedRole === "Both")) {
      this.isAllowedVar = true
    }
    else {
      this.isAllowedVar = false
    }

  }

  render() {
    this.handlePermissions();
    if (this.isAllowedVar) {
      if (!this.state.loading) {
        if (!this.isLoggedInUserVar && !this.props.isAlowedBeforeLogin) {
          return <Redirect to={{ pathname: '/signup' }} />;
        } else {
          return <Route {...this.props} />;
        }
      } else {
        return null;
      }
    } else {
      if(this.props.isAlowedBeforeLogin){
        return <Route {...this.props} />;
      }
      else{
        window.history.back();
        return null;
      }

    }
  }
}
